<template>
  <div class="container">
    <div class="header">
      <ul class="header-user">
        <li class="avatar" />
        <li class="cont">
          <div class="phone">{{ phone }}</div>
          <div class="tag">领取你的专属会员福利～</div>
        </li>
        <li class="record">
          <div class="btn" @click="handlePage('/carOwner_zhengxing/receive/record')">
            <img
              class="middle"
              src="https://cdn.qiumo.net.cn/allsmarketmaterial/receive_zhengxing/icon_record.png"
            /><span class="middle">领取记录</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="content">
      <div class="card">
        <div class="card-item" v-for="(item, index) in list" :key="index">
          <img class="card_img" :src="item.img" />
          <van-button
            :class="['btn', item.class]"
            @click="handlePage(item.page, item.title)"
          >
            去领取
          </van-button>
        </div>
      </div>
      <div class="call">
        客服热线：{{
          appName == "huanxinxiang-zhengxing"
            ? "4001180238"
            : appName == "huanxinxiang-yunxian"
            ? "4006166070"
            : appName == "huanxinxiang-hailuo"
            ? "4001002501"
            : appName == "huanxinxiang"
            ? "4001189599"
            : ""
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { alipayLogin } from "@/api/carOwner_zhengxing/receive.js";
import { isWeixin, setAppName } from "@/utils/common";
import { wechatLogin, getOpenId } from "@/api/tao.js";

export default {
  data() {
    return {
      token: "",
      auth_code: "",
      appName: "",
    };
  },
  computed: {
    ...mapGetters(["carOwner_zhengxing_user_info", "carOwner_zhengxing_token"]),
    phone() {
      const reg = /(\d{3})\d*(\d{4})/;
      let str = this.carOwner_zhengxing_user_info.phone_number;
      if (!str) return "";
      str = str.replace(reg, "$1****$2");
      return str;
    },
    list() {
      const data = {
        车主会员: {
          page: "/carOwner_zhengxing/receive/membership",
          class: "",
          img: "https://cdn.qiumo.net.cn/allsmarketmaterial/receive_zhengxing/card1.png",
        },
        美食会员: {
          page: "/carOwner_zhengxing/receive/membership",
          class: "yellow",
          img: "https://cdn.qiumo.net.cn/allsmarketmaterial/receive_zhengxing/card2.png",
        },
        商超会员: {
          page: "",
          class: "green",
          img: "https://cdn.qiumo.net.cn/allsmarketmaterial/receive_zhengxing/card3.png",
        },
        随心淘会员: {
          page: "",
          class: "orange",
          img: "https://cdn.qiumo.net.cn/allsmarketmaterial/receive_zhengxing/card4.png",
        },
        随心享会员: {
          page: "/carOwner_zhengxing/receive/membership",
          class: "red",
          img: "https://cdn.qiumo.net.cn/allsmarketmaterial/receive_zhengxing/card5.png",
        },
        车主畅享会员: {
          page: "/carOwner_zhengxing/receive/membership",
          class: "",
          img: "https://cdn.qiumo.net.cn/allsmarketmaterial/receive_zhengxing/card6.png",
        },
      };

      let vipList = this.carOwner_zhengxing_user_info.available_vip;
      console.log(vipList);
      let isSXX =
        this.carOwner_zhengxing_user_info.available_vip.length > 0
          ? this.carOwner_zhengxing_user_info.available_vip.includes("随心享会员")
          : false;

      if (!isSXX && this.carOwner_zhengxing_user_info.phone_number == "13735889010") {
        vipList.push("随心享会员");
      }
      if (
        !this.carOwner_zhengxing_user_info.available_vip.includes("车主畅享会员") &&
        this.carOwner_zhengxing_user_info.phone_number == "13735889010"
      ) {
        vipList.push("车主畅享会员");
      }
      return vipList.map((i) => ({
        ...data[i],
        title: i,
      }));
    },
  },
  created() {
    this.appName = setAppName();
    const { auth_code = "" } = this.$route.query;

    this.auth_code = auth_code;
    console.log(this.auth_code, this.appName, this.$route.query);
    if (this.appName == "huanxinxiang-hailuo") {
      this["carOwner_zhengxing/getUserInfo"]();
      return;
    }
    // 支付宝登录
    if (!isWeixin()) {
      if (!auth_code) {
        const { origin, pathname } = location;
        const redirect_uri = encodeURIComponent(origin + pathname);
        const appId =
          this.appName == "huanxinxiang-zhengxing"
            ? "2021004138631741"
            : this.appName == "huanxinxiang-yunxian"
            ? "2021004142649281"
            : "";
        const url = `https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=${appId}&scope=auth_base&redirect_uri=${redirect_uri}`;
        location.href = url;
      }
      if (!!auth_code) {
        this.login();
      }
    } else {
      // 微信登录
      this.wxLogin();
    }
  },
  methods: {
    ...mapMutations(["carOwner_zhengxing/SET_TOKEN"]),
    ...mapActions(["carOwner_zhengxing/getUserInfo"]),
    async login() {
      const res = await alipayLogin({ auth_code: this.auth_code });
      this["carOwner_zhengxing/SET_TOKEN"](res.token);
      this["carOwner_zhengxing/getUserInfo"]();
    },
    async wxLogin() {
      const urlCode = new URLSearchParams(window.location.search).get("code");

      if (!urlCode && !this.carOwner_zhengxing_token) {
        let redirectUri = encodeURIComponent(`${location.href}`);
        this["carOwner_zhengxing/SET_TOKEN"]("");
        const appId =
          this.appName == "huanxinxiang-zhengxing"
            ? "wxabe4ba29a3d48904"
            : this.appName == "huanxinxiang-yunxian"
            ? "wx5a2212eae502417d"
            : "";
        const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
        window.location.replace(url);
        return;
      }
      if (!!this.carOwner_zhengxing_token) {
        this["carOwner_zhengxing/getUserInfo"]();
      } else {
        this.loading = true;
        const { wechat_open_id = "", message = "", code = 0 } = await getOpenId({
          login_code: urlCode,
        });
        if (code != 0) {
          this.$toast.fail(message);
          this.$router.replace({
            path: "/carOwner_zhengxing/receive/login",
            query: {
              wechat_open_id,
            },
          });
        } else {
          const res = await wechatLogin({ wechat_open_id: wechat_open_id });
          if (res.code == 0) {
            this["carOwner_zhengxing/SET_TOKEN"](res.token);
            this["carOwner_zhengxing/getUserInfo"]();
          } else {
            this.$toast.fail(res.message);
            this.$router.replace({
              path: "/carOwner_zhengxing/receive/login",
              query: {
                wechat_open_id,
              },
            });
          }
        }
        this.loading = false;
      }
    },
    handlePage(path = "", title = "") {
      this.$router.push({
        path: path,
        query: {
          title,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  padding-bottom: 100px;

  .header {
    height: 342px;
    padding-top: 26px;
    box-sizing: border-box;
    background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/receive_zhengxing/bg.png")
      center center/contain no-repeat;

    &-user {
      display: flex;
      align-items: center;
      color: #fff;

      .avatar {
        width: 145px;
        height: 148px;
        line-height: 148px;
        background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/receive_zhengxing/avatar.png")
          center center/contain no-repeat;
        text-align: center;
      }

      .cont {
        flex: 1 1 auto;
        .tag {
          font-weight: 400;
          font-size: 24px;
          color: #ffffff;
          line-height: 33px;
          padding-top: 14px;
        }
        .phone {
          font-size: 36px;
          color: #ffffff;
          line-height: 50px;
        }
      }

      .record {
        padding-right: 30px;
        .btn {
          display: block;
          width: 158px;
          height: 46px;
          line-height: 46px;
          background: rgba(255, 255, 255, 0.2);
          box-shadow: 0px 4px 10px 0px rgba(59, 124, 217, 0.21);
          border-radius: 23px;
          font-size: 22px;
          box-sizing: border-box;
          padding-left: 16px;
          border: none;

          img {
            width: 24px;
            padding-right: 14px;
          }

          .middle {
            vertical-align: middle;
          }
        }
      }
    }
  }
  .content {
    padding: 0 28px 0 32px;
    box-sizing: border-box;

    .card {
      min-height: 1036px;
      overflow-y: auto;
      margin-top: -150px;

      &-item {
        position: relative;

        .btn {
          position: absolute;
          top: 160px;
          left: 40px;
          width: 130px;
          height: 44px;
          background: linear-gradient(90deg, #4ca8fd 0%, #81c9fe 100%);
          border-radius: 22px;
          font-size: 26px;
          color: #ffffff;
          border: none;
          padding: 0;
          // margin-top: -50px;
        }

        .orange {
          background: linear-gradient(90deg, #ffa550 0%, #ffbd88 100%);
        }
        .red {
          background: linear-gradient(90deg, #ff5261 0%, #ff88b8 100%);
        }
        .green {
          background: linear-gradient(90deg, #2cc1b8 0%, #99e0cd 100%);
        }
        .yellow {
          background: linear-gradient(90deg, #ffcb35 0%, #fedc73 100%);
        }
      }

      &_img {
        width: 100%;
      }
    }

    .call {
      padding-top: 60px;
      font-size: 24px;
      color: #999999;
      text-align: center;
    }
  }
}
</style>
